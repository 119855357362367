import { environment } from '@environment/environment';

export abstract class BaseHttp {
	public apiRoot = '';
	serversList: string[] = environment.appSettings.serversList;

	protected constructor() {
		this.updateApiRoot();
		window.addEventListener('storage', () => this.updateApiRoot(), false);
	}

	protected getCurrentServer() {
		const fromLocalStorage = localStorage.getItem('server') || this.serversList[0];
		if (this.serversList.includes(fromLocalStorage) || this.serversList.length === 0) {
			localStorage.setItem('server', fromLocalStorage);
			return fromLocalStorage;
		}
		localStorage.setItem('server', this.serversList[0]);
		return this.serversList[0];
	}

	updateApiRoot() {
		this.apiRoot = '/api/v1';
	}
}
