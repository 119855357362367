import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {tap} from "rxjs/operators";
import {IEditComponent, ILtaComponent} from "@atl/admin/models/interfaces";
import {ComponentsHttpService} from "@atl/admin/models/services/components-http.service";
import {IModelShort} from "@atl/lacerta-ui-common";

@Injectable()
export class ComponentsService {
    public componentsSubject: BehaviorSubject<ILtaComponent[]> = new BehaviorSubject<ILtaComponent[]>([]);
    public components$ = this.componentsSubject.asObservable();
    public active$: Subject<ILtaComponent> = new Subject<ILtaComponent>();

    constructor(private componentsHttp: ComponentsHttpService) {
    }

    public getComponents(): Observable<ILtaComponent[]> {
        return this.componentsHttp.getComponents().pipe(tap(components => {
            this.componentsSubject.next(components)
        }))
    }

    public getComponentsWithoutSvg(): Observable<ILtaComponent[]> {
        return this.componentsHttp.getComponentsWithoutSvg().pipe(tap(v => {
            this.componentsSubject.next(v)
        }))
    }

    public getComponentById(id: number): Observable<ILtaComponent> {
        return this.componentsHttp.getComponentById(id)
            .pipe(
                tap((component) => {
                    if (!this.componentsSubject.value) return;
                    const updatedComponents = this.componentsSubject.value.map(v => {
                        if (v.id !== id) return v
                        return component
                    })
                    this.componentsSubject.next(updatedComponents)
                })
            )
    }

    public updateComponent(id: number, body: IEditComponent): Observable<ILtaComponent> {
        return this.componentsHttp.updateComponent(id, body)
    }

    public getModelsByComponentId(id: number): Observable<IModelShort[]> {
        return this.componentsHttp.getModelsByComponentId(id)
    }
}
