import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseHttp} from '@atl/shared/abstract';
import {
    DriverIntegrations,
    IDriver,
    IDriverAccount,
    IDriverAttachmentInfo,
    IDriverDetailed,
    IDriverIntegration,
    IDriverMapping,
    IDriverSaveData,
    IDriverTree
} from '../interfaces'

@Injectable({
    providedIn: 'root'
})
export class DriverHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    public getDrivers(): Observable<IDriver[]> {
        const url = `${this.apiRoot}/daemons/drivers`;
        return this.http.get<IDriver[]>(url, {withCredentials: true})
    }

    public getDriverById(id: number): Observable<IDriverDetailed> {
        const url = `${this.apiRoot}/daemons/drivers/${id}`;
        return this.http.get<IDriverDetailed>(url, {withCredentials: true})
    }

    public createDriver(driverInfo: IDriverSaveData): Observable<IDriverDetailed> {
        const url = `${this.apiRoot}/daemons/drivers`;
        return this.http.post<IDriverDetailed>(url, driverInfo, {withCredentials: true})
    }

    public editDriver(id: number, driverInfo: IDriverSaveData): Observable<IDriverDetailed> {
        const url = `${this.apiRoot}/daemons/drivers/${id}`;
        return this.http.put<IDriverDetailed>(url, driverInfo, {withCredentials: true})
    }

    public deleteDriver(id: number): Observable<string> {
        const url = `${this.apiRoot}/daemons/drivers/${id}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'})
    }

    public getDriverTree(id: number): Observable<IDriverTree> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/tree`;
        return this.http.get<IDriverTree>(url, {withCredentials: true})
    }

    public getDriverTreeChildren(driverId: number, driverTreeItemId: number): Observable<IDriverTree> {
        const url = `${this.apiRoot}/daemons/drivers/${driverId}/tree/${driverTreeItemId}/childs`;
        return this.http.get<IDriverTree>(url, {withCredentials: true})
    }

    public updateDriverTree(id: number): Observable<string> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/tree/update`;
        return this.http.put(url, {}, {withCredentials: true, responseType: 'text'})
    }

    public getDriverMapping(id: number): Observable<IDriverMapping[]> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/mapping`;
        return this.http.get<IDriverMapping[]>(url, {withCredentials: true})
    }

    public addMappingItemsToDriver(id: number, mappings: IDriverMapping & {
        id: undefined
    }[]): Observable<IDriverMapping[]> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/mapping`;
        return this.http.post<IDriverMapping[]>(url, mappings, {withCredentials: true})
    }

    public editDriverMappingItem(mapping: IDriverMapping): Observable<IDriverMapping> {
        const url = `${this.apiRoot}/daemons/drivers/${mapping.id}/mapping`;
        return this.http.put<IDriverMapping>(url, mapping, {withCredentials: true})
    }

    public deleteMappingItem(id: number): Observable<string> {
        const url = `${this.apiRoot}/daemons/drivers/mapping/${id}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'})
    }

    public getDriverAttachmentsInfo(id: number): Observable<IDriverAttachmentInfo[]> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/files`;
        return this.http.get<IDriverAttachmentInfo[]>(url, {withCredentials: true})
    }

    public getDriverAttachment(id: number): Observable<string> {
        const url = `${this.apiRoot}/daemons/drivers/files/${id}`;
        return this.http.get(url, {withCredentials: true, responseType: 'text'})
    }

    public addAttachmentToDriver(id: number, file: FormData): Observable<IDriverAttachmentInfo> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/files`;
        return this.http.post<IDriverAttachmentInfo>(url, file, {withCredentials: true})
    }

    public deleteAttachment(id: number): Observable<string> {
        const url = `${this.apiRoot}/daemons/drivers/files/${id}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'})
    }

    public getDriverIntegrations(integration: DriverIntegrations): Observable<IDriverIntegration[]> {
        const url = `${this.apiRoot}/integrations/${integration}`;
        return this.http.get<IDriverIntegration[]>(url, {withCredentials: true})
    }

    public createDriverIntegration(integration: DriverIntegrations, body: Omit<IDriverIntegration, 'id'>): Observable<IDriverIntegration> {
        const url = `${this.apiRoot}/integrations/${integration}`;
        return this.http.post<IDriverIntegration>(url, body, {withCredentials: true})
    }

    public editDriverIntegration(integration: DriverIntegrations, body: IDriverIntegration): Observable<IDriverIntegration> {
        const url = `${this.apiRoot}/integrations/${integration}/${body.id}`;
        return this.http.put<IDriverIntegration>(url, body, {withCredentials: true})
    }

    public deleteDriverIntegration(integration: DriverIntegrations, id: number): Observable<string> {
        const url = `${this.apiRoot}/integrations/${integration}/${id}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'})
    }

    public getDriverAccounts(id: number): Observable<IDriverAccount[]> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/accounts`;
        return this.http.get<IDriverAccount[]>(url, {withCredentials: true})
    }

    public createDriverAccount(id: number, account: Omit<IDriverAccount, 'id'>): Observable<IDriverAccount> {
        const url = `${this.apiRoot}/daemons/drivers/${id}/accounts`;
        return this.http.post<IDriverAccount>(url, account, {withCredentials: true})
    }

    public updateDriverAccount(id: number, body: Omit<IDriverAccount, 'login' | 'id'>): Observable<IDriverAccount> {
        const url = `${this.apiRoot}/daemons/drivers/accounts/${id}`;
        return this.http.put<IDriverAccount>(url, body, {withCredentials: true})
    }

    public deleteDriverAccount(id: number): Observable<string> {
        const url = `${this.apiRoot}/daemons/drivers/accounts/${id}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'})
    }
}
