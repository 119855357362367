import {Injectable} from '@angular/core';
import {BaseHttp} from '@atl/shared/abstract/base-http';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    IObject,
    ICreateModel,
    ICreateModelChild,
    IModel,
    IModelChild,
    IModelComponent,
    IModelPathItem,
    IModelTree,
    ISetModelComponent,
    IType,
    IUpdateModel,
    IUpdateModelChild
} from "@atl/lacerta-ui-common";
import {InterceptorSkipHeader} from "@atl/interceptors/error-interceptor";

@Injectable({
    providedIn: 'root'
})
export class ModelsHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    getAllTypes(): Observable<{ types: IType[] }> {
        const url = `${this.apiRoot}/type`;
        return this.http.get<{ types: IType[] }>(url, {withCredentials: true})
    }

    getAllModels(): Observable<IModel[]> {
        const url = `${this.apiRoot}/models`;
        return this.http.get<IModel[]>(url, {withCredentials: true})
    }

    getModelById(id: number): Observable<IModel> {
        const url = `${this.apiRoot}/models/${id}`;
        return this.http.get<IModel>(url, {withCredentials: true})
    }

    getModelTreeById(id: number, withParams: boolean): Observable<IModelTree> {
        const url = `${this.apiRoot}/models/${id}/tree`;
        let params = new HttpParams();
        params = params.append('with_params', withParams)
        return this.http.get<IModelTree>(url, {withCredentials: true, params})
    }

    createModel(model: ICreateModel): Observable<IModel> {
        const url = `${this.apiRoot}/models`;
        return this.http.post<IModel>(url, model, {withCredentials: true})
    }

    updateModel(id: number, model: IUpdateModel): Observable<IModel> {
        const url = `${this.apiRoot}/models/${id}`;
        return this.http.put<IModel>(url, model, {withCredentials: true})
    }

    deleteModel(id: number, del_aff_obj: boolean): Observable<number> {
        const url = `${this.apiRoot}/models/${id}`;
        let params = new HttpParams();
        params = params.append('del_aff_obj', del_aff_obj)
        return this.http.delete(url, {withCredentials: true, responseType: 'text', params})
            .pipe(map(() => id));
    }

    getModelChildrenById(id: number): Observable<IModelChild[]> {
        const url = `${this.apiRoot}/models/${id}/childs`;
        return this.http.get<IModelChild[]>(url, {withCredentials: true})
    }

    addChildToModel(modelId: number, child: ICreateModelChild): Observable<IModel> {
        const url = `${this.apiRoot}/models/${modelId}/childs`;
        return this.http.post<IModel>(url, child, {withCredentials: true})
    }

    updateModelChild(modelId: number, child: IUpdateModelChild): Observable<IModelChild> {
        const url = `${this.apiRoot}/models/childs/${modelId}`;
        return this.http.put<IModelChild>(url, child, {withCredentials: true})
    }

    deleteChildFromModelById(childId: number): Observable<number> {
        const url = `${this.apiRoot}/models/childs/${childId}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'})
            .pipe(map(() => childId));
    }

    getObjectsByModelId(id: number): Observable<IObject[]> {
        const url = `${this.apiRoot}/models/${id}/objects`;
        return this.http.get<IObject[]>(url, {withCredentials: true});
    }

    getModelComponents(modelId: number): Observable<IModelComponent[]> {
        const url = `${this.apiRoot}/models/${modelId}/components`;
        return this.http.get<IModelComponent[]>(url, {withCredentials: true});
    }

    setModelComponent(modelId: number, componentId: number, body: ISetModelComponent): Observable<IModelComponent> {
        const url = `${this.apiRoot}/models/${modelId}/components/${componentId}`;
        return this.http.post<IModelComponent>(url, body, {withCredentials: true})
    }

    deleteComponentFromModel(modelId: number, componentId: number): Observable<string> {
        const url = `${this.apiRoot}/models/${modelId}/components/${componentId}`;
        return this.http.delete(url, {withCredentials: true, responseType: 'text'});
    }

    searchForModel(str: string, capacity: number = 50): Observable<IModelPathItem[]> {
        const url = `${this.apiRoot}/models/search`;
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<IModelPathItem[]>(url, {
            withCredentials: true, headers, params: {
                str,
                capacity
            }
        });
    }
}
