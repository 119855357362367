import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs'
import {
    IDriver,
    IDriverAccount,
    IDriverAttachmentInfo,
    IDriverDetailed,
    IDriverMapping,
    IDriverSaveData,
    IDriverTree
} from '../interfaces';
import {DriverHttpService} from './drivers-http.service'
import {switchMap, tap} from "rxjs/operators";

@Injectable()
export class DriversService {
    
    public activeDriverAccountsSubject: BehaviorSubject<IDriverAccount[] | null> = new BehaviorSubject<IDriverAccount[] | null>(null)
    public activeDriverAccounts$: Observable<IDriverAccount[] | null> = this.activeDriverAccountsSubject.asObservable()
    private activeDriverSubject = new BehaviorSubject<IDriverDetailed | null>(null);
    public activeDriver$ = this.activeDriverSubject.asObservable();
    private activeDriverAttachmentsSubject = new BehaviorSubject<IDriverAttachmentInfo[]>([]);
    public activeDriverAttachments$ = this.activeDriverAttachmentsSubject.asObservable();
    private driversSubject = new BehaviorSubject<IDriver[]>([]);
    public drivers$ = this.driversSubject.asObservable();

    constructor(private driverHttp: DriverHttpService) {
    }

    public getDriverAccounts(id: number) {
        return this.driverHttp.getDriverAccounts(id)
            .pipe(
                tap((value) => this.activeDriverAccountsSubject.next(value))
            )
    }


    public getDrivers(): Observable<IDriver[]> {
        return this.driverHttp.getDrivers()
            .pipe(
                tap(drivers => {
                    this.driversSubject.next(drivers);
                    const activeDriver = this.activeDriverSubject.value;
                    if (activeDriver) {
                        const updatedDriver = drivers.find(driver => driver.id === activeDriver.id);
                        if (updatedDriver) {
                            // this.activeDriverSubject.next(updatedDriver);
                        }
                    }
                }),
            );
    }

    public getDriverTree(id: number): Observable<IDriverTree> {
        return this.driverHttp.getDriverTree(id)
    }

    public getDriverTreeChildren(driverId: number, driverTreeItemId: number): Observable<IDriverTree> {
        return this.driverHttp.getDriverTreeChildren(driverId, driverTreeItemId)
    }

    public updateDriverTree(id: number): Observable<string> {
        return this.driverHttp.updateDriverTree(id)
    }

    public setActiveDriver(driver: IDriver | null): Observable<IDriverDetailed | null> {
        if (driver) {
            return this.driverHttp.getDriverById(driver.id)
                .pipe(tap((driver) => this.activeDriverSubject.next(driver)))
        } else {
            return of(null)
                .pipe(tap(() => this.activeDriverSubject.next(null)))
        }

    }

    public submitDriver(driverInfo: IDriverSaveData, id: number) {
        if (id) {
            return this.driverHttp.editDriver(id, driverInfo)
                .pipe(tap(driver => {
                    this.driversSubject.next(this.driversSubject.value.map(d => {
                        if (d.id === driver.id) {
                            return driver
                        } else {
                            return d
                        }
                    }))
                }))
        } else {
            if (!driverInfo.descr) {
                driverInfo.descr = ''
            }
            return this.driverHttp.createDriver(driverInfo)
                .pipe(
                    tap(driver => {
                        this.activeDriverSubject.next(driver)
                        this.driversSubject.next([...this.driversSubject.value, driver])
                    }))
        }
    }

    public deleteDriver(id: number): Observable<string> {
        return this.driverHttp.deleteDriver(id)
            .pipe(tap(() => {
                this.activeDriverSubject.next(null)
                this.driversSubject.next(this.driversSubject.value.filter(driver => driver.id !== id))
            }))
    }

    public toggleDriver(id: number, needRun: boolean): Observable<IDriver> {
        return this.driverHttp.getDriverById(id).pipe(
            switchMap((driver) => {
                let driverSaveData: IDriverSaveData = {
                    run_setting: driver.run_setting
                };
                delete driverSaveData.run_setting.bind_to_default_node;
                delete driverSaveData.run_setting.default_node_id;
                driverSaveData.run_setting.need_run = needRun;
                return this.driverHttp.editDriver(id, driverSaveData)
                    .pipe(tap(driver => {
                        this.driversSubject.next(this.driversSubject.value.map(d => {
                            if (d.id === driver.id) {
                                return driver
                            } else {
                                return d
                            }
                        }))
                        if (this.activeDriverSubject.value && this.activeDriverSubject.value.id === driver.id) {
                            this.activeDriverSubject.next(driver)
                        }
                    }))
            })
        )
    }

    public addMappingItemsToDriver(id: number, mappings: IDriverMapping & {
        id: undefined
    }[]): Observable<IDriverMapping[]> {
        return this.driverHttp.addMappingItemsToDriver(id, mappings)
    }

    public addBlankDriver() {
        this.activeDriverSubject.next({
            id: null,
            name: null,
            node_id: null,
            descr: null,
            driver_status: null,
            driver_info: {
                type_id: null,
                type_name: null,
                has_tree: null,
                settings: []
            },
            run_setting: {
                bind_to_default_node: false,
                default_node_id: null,
                need_run: null
            },
            tm_change_run_status: 0
        })
    }

    public getDriverAttachmentsInfo(id: number) {
        this.activeDriverAttachmentsSubject.next([]);
        return this.driverHttp.getDriverAttachmentsInfo(id)
            .pipe(tap((files) => this.activeDriverAttachmentsSubject.next(files)));
    }

    public addAttachmentToDriver(id: number, file: File) {
        const formData = new FormData();
        formData.append('driver_file', file, file.name);
        return this.driverHttp.addAttachmentToDriver(id, formData)
            .pipe(
                tap((attachment => this.activeDriverAttachmentsSubject.next([...this.activeDriverAttachmentsSubject.value, attachment]))));
    }

    public downloadAttachment(id: number, name: string) {
        return this.driverHttp.getDriverAttachment(id)
            .pipe(tap((file) => {
                const attachment = new File([file], name, {type: "text/plain"});
                const a = document.createElement("a");
                const url = URL.createObjectURL(attachment);
                a.href = url;
                a.download = name;
                a.click();
            }))
    }

    public deleteAttachment(id: number) {
        return this.driverHttp.deleteAttachment(id)
            .pipe(
                tap(() => this.activeDriverAttachmentsSubject.next(this.activeDriverAttachmentsSubject.value.filter(item => item.id !== id))));
    }

    public clearAttachments() {
        this.activeDriverAttachmentsSubject.next([]);
    }

}
