import {DatePipe, registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '@environment/environment';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {ClipboardModule} from 'ngx-clipboard';
import {ColorPickerModule} from 'ngx-color-picker';
import {AppComponent} from './app.component';
import {HttpLoaderFactory} from './app.settings';
import {AppRoutingModule} from './routing/app-routing.module';
import {ErrorInterceptorImpl} from '@atl/interceptors/error-interceptor'
import {NanosecondsToMillisecondsPipe} from "@atl/pipes/nanoseconds-to-milliseconds/nanoseconds-to-milliseconds.pipe";
import {LtaDatePipe} from "@atl/pipes/lta-date/lta-date.pipe";
import {FilterPipe} from "@atl/pipes/filter/filter.pipe";
import {FlashMessagesModule} from "@atl/modules/flash-messages";
import {ReactiveFormsModule} from '@angular/forms';
import {TreeModule} from "@ali-hm/angular-tree-component";
import {
    CommonAuthModule,
    CommonServicesModule,
    LtaInfoPopupService,
    SPRITES_ROOT,
    SvgService,
    WebsocketModule
} from "@atl/lacerta-ui-common";
import {AuthServiceImpl, UserServiceImpl} from "@atl/authorization/services";

registerLocaleData(localeRu, 'ru');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        CommonServicesModule.forRoot({
            env: environment,
            errorInterceptor: ErrorInterceptorImpl
        }),
        CommonAuthModule.forRoot({
            authService: AuthServiceImpl,
            userService: UserServiceImpl
        }),
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ClipboardModule,
        ColorPickerModule,
        WebsocketModule.config({
            url: environment.appSettings.websocketUrl
        }),
        FlashMessagesModule,
        ReactiveFormsModule,
        TreeModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'ru-ru'},
        {provide: SPRITES_ROOT, useValue: '/assets/sprites/sprite.svg'},
        HttpClient,
        TranslatePipe,
        NanosecondsToMillisecondsPipe,
        DatePipe,
        LtaDatePipe,
        FilterPipe,
        SvgService,
        LtaInfoPopupService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
