import {Inject, Injectable} from "@angular/core";
import {EventWebsocketMessage, IEvent, IEventType, WebsocketService} from "@atl/lacerta-ui-common";
import {BehaviorSubject, Observable} from "rxjs";
import {EventsService} from "@atl/main/alerts/services/events.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {EventTypesService} from "@atl/admin/alerts/services";
import {map} from "rxjs/operators";

export const RAISED_EVENTS_WEBSOCKET = 'ACTIVE_EVENTS_WEBSOCKET'

@UntilDestroy()
@Injectable()
export class RaisedEventsService {
    public uncheckedEventsCount$ = new BehaviorSubject<string>(null)
    public lastEventColor$ = new BehaviorSubject<string>(null)
    public hasRaisedEvents$ = new BehaviorSubject<boolean>(false)
    public raisedEvents$ = new BehaviorSubject<(IEvent & { event_class?: IEventType })[]>([])
    public uncheckedEvents$ = new BehaviorSubject<(IEvent & { event_class?: IEventType })[]>([])
    private events$: Observable<EventWebsocketMessage>
    private unsubscribeFn: () => void

    constructor(@Inject(RAISED_EVENTS_WEBSOCKET) private websocketService: WebsocketService, private eventService: EventsService, private eventTypesService: EventTypesService) {
    }

    public destroy() {
        if (this.unsubscribeFn) {
            this.unsubscribeFn()
        }
    }

    public subscribeToObjectEvents(objectId: number) {
        return this.raisedEvents$.pipe(map(events => events.filter(ev => objectId ? ev.object_path.some(p => p.id === objectId) : true)))
    }

    public init() {
        this.eventTypesService.getEventTypes().subscribe(() => {
            const [events$, unsubscribeFn] = this.websocketService.subscribeToEvents({});
            this.events$ = events$
            this.unsubscribeFn = unsubscribeFn
            this.events$.pipe(untilDestroyed(this)).subscribe(ev => this.eventService.handleEventSignal(ev))

            this.eventService.addEventsFilters({
                limit: 99999,
                dataType: 'raised'
            })


            this.eventService.getEvents(true)
        })


        this.eventService.events$.pipe(untilDestroyed(this)).subscribe(events => {
            this.raisedEvents$.next(events)
            this.uncheckedEvents$.next(events.filter(value => value.event_class.can_check).filter(e => !EventsService.isEventChecked(e)))
            const uncheckedEvents = this.uncheckedEvents$.value

            this.uncheckedEventsCount$.next(uncheckedEvents.length ? uncheckedEvents.length > 99 ? '99+' : uncheckedEvents.length.toString() : '')
            this.lastEventColor$.next(
                uncheckedEvents.length ?
                    uncheckedEvents[0]?.event_class?.color_background :
                    events[0]?.event_class?.color_background_checked
            )
            this.hasRaisedEvents$.next(events.length > 0)
        })


    }
}
