import {Injectable} from '@angular/core';
import {BaseHttp} from '@atl/shared/abstract/base-http';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IFigmaFileMeta} from '@atl/shared/interfaces';
import {FigmaStatusRes, ISettings} from '@atl/admin/settings/interfaces';

@Injectable({
    providedIn: 'root'
})
export class SettingsHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    getSettings(): Observable<ISettings[]> {
        const url = `${this.apiRoot}/settings`;
        return this.http.get<ISettings[]>(url, {withCredentials: true});
    }

    addSettings(settings: ISettings[]): Observable<ISettings[]> {
        const url = `${this.apiRoot}/settings`;
        return this.http.post<ISettings[]>(url, settings, {withCredentials: true});
    }

    getFigmaFiles(): Observable<IFigmaFileMeta[]> {
        const url = `${this.apiRoot}/figma/files`;
        return this.http.get<IFigmaFileMeta[]>(url, {withCredentials: true});
    }

    syncFigma(): Observable<null> {
        const url = `${this.apiRoot}/figma/sync`;
        return this.http.get<null>(url, {withCredentials: true});
    }

    getFigmaStatus(): Observable<FigmaStatusRes> {
        const url = `${this.apiRoot}/figma/sync/status`;
        return this.http.get<FigmaStatusRes>(url, {withCredentials: true});
    }
}
