import {Pipe, PipeTransform} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import * as moment from 'moment-timezone';
import {TimeService} from "@atl/shared/services/time.service";

@UntilDestroy()
@Pipe({
    name: 'ltaDate'
})
export class LtaDatePipe implements PipeTransform {

    constructor(private timeService: TimeService) {
    }

    transform(value: number, format = 'DD.MM.YYYY HH:mm:ss'): string {
        if (this.timeService.serverTimeDelta === undefined) return
        return moment(value).add(this.timeService.serverTimeDelta).format(format)
    }
}
