import {Injectable} from '@angular/core';
import {BaseHttp} from '@atl/shared/abstract/base-http';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {INewPasswordForm, IUser, IUserPicture, UserFilter} from '../interfaces'
import {IUserToken} from '@app/@atl/modules/modals/token-dialog/token-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class UsersHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    getAllUsers(filter: UserFilter): Observable<IUser[]> {
        const url = `${this.apiRoot}/users`;
        return this.http.get<IUser[]>(url, {withCredentials: true, params: {filter: filter ? filter : 0}})
    }

    getUserById(id: number): Observable<IUser> {
        const url = `${this.apiRoot}/users/${id}`;
        return this.http.get<IUser>(url, {withCredentials: true})
    }

    createUser(user: Partial<IUser>): Observable<IUser> {
        const url = `${this.apiRoot}/users`;
        const body: Partial<IUser> = {
            name: user.name,
            login: user.login,
            email: user.email,
            password: user.password,
            role: user.role,
            role_id: user.role_id,
            video_screen_id: user.video_screen_id,
        };
        return this.http.post<IUser>(url, body, {withCredentials: true})
    }

    updateUser(user: Partial<IUser>): Observable<IUser> {
        const url = `${this.apiRoot}/users/${user.id}`;
        return this.http.put<IUser>(url, user, {withCredentials: true})
    }

    deleteUser(user: IUser): Observable<null> {
        const url = `${this.apiRoot}/users/${user.id}`;
        return this.http.delete<null>(url, {withCredentials: true})
    }

    editPassword(id: number, passwords: INewPasswordForm): Observable<string> {
        const url = `${this.apiRoot}/users/${id}/password`;
        return this.http.put<string>(url, passwords, {withCredentials: true})
    }

    activateUser(id: number): Observable<string> {
        const url = `${this.apiRoot}/users/${id}/activate`;
        return this.http.put<string>(url, {}, {withCredentials: true})
    }

    deactivateUser(id: number): Observable<string> {
        const url = `${this.apiRoot}/users/${id}/deactivate`;
        return this.http.put<string>(url, {}, {withCredentials: true})
    }

    forceEditPassword(id: number, passwords: Omit<INewPasswordForm, 'password_old'>): Observable<string> {
        const url = `${this.apiRoot}/users/${id}/password/change_force`;
        return this.http.put<string>(url, passwords, {withCredentials: true})
    }

    getUserPictureById(id: number): Observable<IUserPicture> {
        const url = `${this.apiRoot}/users/${id}/avatar`;
        return this.http.get<IUserPicture>(url, {withCredentials: true})
    }

    setUserPictureById(id: number, picture: string | ArrayBuffer): Observable<string> {
        const url = `${this.apiRoot}/users/${id}/avatar`;
        return this.http.post<string>(url, {avatar: picture}, {withCredentials: true})
    }

    deleteUserPictureById(id: number): Observable<string> {
        const url = `${this.apiRoot}/users/${id}/avatar`;
        return this.http.delete<string>(url, {withCredentials: true})
    }


    createUserToken(userToken: any, id: number): Observable<IUserToken> {
        const url = `${this.apiRoot}/users/${id}/tokens`;
        return this.http.post<IUserToken>(url, userToken, {withCredentials: true})
    }

    getUserTokens(id: number): Observable<IUserToken[]> {
        const url = `${this.apiRoot}/users/${id}/tokens`;
        return this.http.get<IUserToken[]>(url, {withCredentials: true})
    }

    getUserTokenById(id: number, tokenId: number): Observable<IUserToken> {
        const url = `${this.apiRoot}/users/${id}/tokens/${tokenId}`;
        return this.http.get<IUserToken>(url, {withCredentials: true})
    }

    editUserToken(userToken: any, id: number, tokenId: number): Observable<IUserToken> {
        const url = `${this.apiRoot}/users/${id}/tokens/${tokenId}`;
        return this.http.put<IUserToken>(url, userToken, {withCredentials: true})
    }

    deleteUserTokens(id: number) {
        const url = `${this.apiRoot}/users/${id}/tokens`;
        return this.http.delete<string>(url, {withCredentials: true})
    }

    deleteUserTokenById(id: number, tokenId: number) {
        const url = `${this.apiRoot}/users/${id}/tokens/${tokenId}`;
        return this.http.delete<string>(url, {withCredentials: true})
    }
}
