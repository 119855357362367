import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ISearchObjectTreeItemWithHMI} from "@atl/modules/modals/object-search-modal/object-search-modal.component";
import {IVideoScreen} from "@atl/admin/hmi/interfaces";
import {UrlService} from "@atl/shared/services";
import {ARGUMENTS_QUERY_PARAM} from "@atl/main/hmi/components/hmi-page/hmi-page.component";

@UntilDestroy()
@Component({
    selector: 'lta-tree-object-mnemo-item',
    templateUrl: 'tree-object-mnemo-item.component.html',
    styleUrls: ['tree-object-mnemo-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeObjectMnemoItemComponent {
    @Input() item: ISearchObjectTreeItemWithHMI;

    constructor(private urlService: UrlService) {
    }

    public filterItems(items: IVideoScreen[]): IVideoScreen[] {
        return items?.filter(i => i.arguments?.length ? i.arguments.length < 2 : true)
    }

    public goTo(item: IVideoScreen) {
        this.urlService.goToHmi({id: item.id, target: '_blank', queryParams: {[ARGUMENTS_QUERY_PARAM]: this.item.id}})
    }
}
