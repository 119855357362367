<aside
        [ngClass]="{open: menuService.isOpen}"
        class="aside"
>
    <a
            [class.full-size]="menuService.isOpen"
            class="logo"
            routerLink="/conf/objects"
    >
        <lta-logo [type]="menuService.isOpen ? 'wide' : 'small'"></lta-logo>
    </a>
    <nav class="nav">
        <ul class="menu">
            <li
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.expand' | translate"
                    class="pb-20"
            >
                <div
                        (click)="menuService.toggleMainMenu()"
                        class="menu-link menu-hide"
                >
                    <span class="menu-link__text">{{ 'lta-menu.hideMenu' | translate }}</span>
                    <lta-svg-icon
                            icon="chevron-left"
                            size="20"
                    ></lta-svg-icon>
                </div>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.roles)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.roles' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/roles"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="roles"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.roles' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.users)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.users' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/users"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="users"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.users' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.models)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.models' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/models"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="models"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.models' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.objects)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.objects' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/objects"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="data"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.objects' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.components)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.hmi-components' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/components"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="component"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.hmi-components' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.hmi)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.hmi' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/mnemo"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="hmi"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.hmi' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.events)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.alerts' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/events"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="alerts"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.alerts' | translate }}</span>
                </a>
            </li>
            <!-- <li>
                <a routerLink="/conf/libraries" class="menu-link" routerLinkActive="isActive">
                    <lta-svg-icon icon="libraries" size="20"></lta-svg-icon>
                    <span class="menu-link__text" translate>lta-menu.libraries</span>
                </a>
            </li> -->
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.cluster)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.cluster' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/cluster"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="cluster"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.cluster' | translate }}</span>
                </a>
            </li>
            <!-- <li>
                <a routerLink="/conf/scenarios" class="menu-link" routerLinkActive="isActive">
                    <lta-svg-icon icon="scenarios" size="20"></lta-svg-icon>
                    <span class="menu-link__text" translate>lta-menu.scenarios</span>
                </a>
            </li> -->
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.services)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.services' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/services"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="wrench"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.services' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.driverTypes)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.driver-types' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/driver-types"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="typesOfDrivers"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.driver-types' | translate }}</span>
                </a>
            </li>
            <li
                    *ngIf="userService.hasPermissionToPage(RestrictedPages.drivers)"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.drivers' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/drivers"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="drivers"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.drivers' | translate }}</span>
                </a>
            </li>
        </ul>
    </nav>
    <div class="aside__bottom">
        <ul class="aside__separator">
            <li
                    *ngLet="user$ | async as user"
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.runtime' | translate"
            >
                <a
                        [routerLink]="user?.video_screen_id ? '/hmi/' + user.video_screen_id : '/'"
                        class="menu-link runtime"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="runtime"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ "lta-menu.runtime" | translate }}</span>
                </a>
            </li>
        </ul>
        <ul class="aside__separator">
            <li
                    [allowed]="!menuService.isOpen"
                    [lta-tooltip]="'lta-menu.settings' | translate"
            >
                <a
                        class="menu-link"
                        routerLink="/conf/settings"
                        routerLinkActive="isActive"
                >
                    <lta-svg-icon
                            icon="settings"
                            size="20"
                    ></lta-svg-icon>
                    <span class="menu-link__text">{{ 'lta-menu.settings' | translate }}</span>
                </a>
            </li>
        </ul>
        <a
                [lta-tooltip]="'lta-menu.personal-account' | translate"
                class="menu-link user"
                routerLink="/conf/personal-account"
                routerLinkActive="isActive"
        >
            <lta-user-avatar
                    [img]="userPicture$ | async"
                    [theme]="'admin'"
            ></lta-user-avatar>
            <span class="user__name"> {{ (user$ | async)?.name }} </span>
        </a>
    </div>
</aside>

<div
        (click)="menuService.toggleMainMenu()"
        [class.visible]="menuService.isOpen"
        class="blackout"
></div>
