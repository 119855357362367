import {ChangeDetectionStrategy, Component, HostListener, Input, ViewChild} from '@angular/core';
import {ContextMenuComponent, ContextMenuModule, ContextMenuService} from "@perfectmemory/ngx-contextmenu";
import {CommonModule} from "@angular/common";

export interface ContextMenuClickEvent<Item> {
    value: Item,
    event: PointerEvent
}

export interface ContextMenuActions<Item> {
    html?: (item: Item) => string,
    style?: (item: Item) => string,
    type?: (item: Item) => 'danger',
    click?: (value: ContextMenuClickEvent<Item>) => void,
    disabled?: (item: Item) => boolean,
    visible?: (item: Item) => boolean,
    divider?: boolean
}

@Component({
    selector: 'lta-context-menu',
    templateUrl: 'context-menu.component.html',
    styleUrls: ['context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ContextMenuModule
    ],
    standalone: true
})
export class LtaContextMenuComponent<Item> {
    @Input() contextMenuActions: ContextMenuActions<Item>[];
    @Input() item: Item;
    @Input() useRClick: boolean = false;
    @Input() style: string = '';
    @ViewChild(ContextMenuComponent) menu: ContextMenuComponent<Item>

    constructor(
        private contextMenuService: ContextMenuService<Item>,
    ) {
    }

    private get isOpen() {
        return this.menu.isOpen
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if (this.useRClick) return
        if (this.isOpen) {
            this.contextMenuService.closeAll();
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.onContextMenu(event, this.item, this.menu)
    }

    @HostListener('contextmenu', ['$event'])
    onRClick(event: MouseEvent) {
        if (!this.useRClick) return
        if (this.isOpen) {
            this.contextMenuService.closeAll();
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.onContextMenu(event, this.item, this.menu)
    }

    public onContextMenu(event: MouseEvent, item: Item, menu: ContextMenuComponent<Item>) {
        this.contextMenuService.closeAll();
        this.contextMenuService.show(menu, {x: event.x, y: event.y, value: item});
        event.preventDefault();
        event.stopPropagation();
    }
}
