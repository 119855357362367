import {ISvgElement} from "@atl/admin/models/interfaces";
import {IModelChild, Types} from "@atl/lacerta-ui-common";

export interface IVideoScreenBase {
    id: number;
    name: string;
    svg: string;
    script: string;
    size: IVideoScreenSize
    grid: IVideoScreenGrid
    type: TypeVideoScreen;
}

interface IVideoScreenElementBase {
    id: number;
    component: IVideoScreenComponent;
}

interface IVideoScreenComponent {
    id: number;
    name: string;
    thumbnail_url: string
}


export interface IVideoScreenElementRuntime {
    id: number;
    svg_element_id: string;
    object: IVideosScreenObject;
    object_id: string;
    model_id: number;
    script1: string;
    script2: string;
    script3: string;
    component_id: number;
    svg_elements: ISvgElement[]
    position: { top: number, left: number, width: number, height: number },
    params: {
        active: boolean,
        objects: string[],
        period: number,
        selections: number[],
        tags: number[],
        tag: number,
        time: number,
        trend_type: number,
        url: string,
        video_screen_id: number
        script: string
    },
    type: VideoScreenElementType
}

export interface IVideosScreenObject {
    descr: string
    id: number
    name: string
    path: string
    type_id: Types
    unit: string
    value?: any
    children?: IVideosScreenObject[]
}

export interface IVideoScreen extends IVideoScreenBase {
    arguments?: IVideoScreenArgument[]
    external_id?: string;
    descr?: string;
    elements?: IVideoScreenElement[];
}

export interface IVideoScreenRuntime extends IVideoScreenBase {
    elements: IVideoScreenElementRuntime[];
    arguments: IVideosScreenObject[];
}

export interface IVideoScreenArgument {
    id: number;
    model: number;
    path?: IModelChild[];
}

export interface IVideoScreenArgumentChild {
    argument: IVideoScreenArgument;
    path: string;
}

interface IVideoScreenGrid {
    columns: number;
    rows: number;
}

interface IVideoScreenSize {
    height: number;
    width: number;
}

export interface IVideoScreenElement extends Partial<IVideoScreenElementBase> {
    script: string;
    argument?: IVideoScreenArgument;
    object?: IVideoScreenElementObject;
    params?: IVideoScreenElementParams;
    position?: IVideoScreenElementPosition;
    svg_element_id?: string;
    type?: VideoScreenElementType;
}

export enum VideoScreenElementType {
    Mnemo = 1,
    IFrame = 2,
    Script = 3
}

export interface IVideoScreenElementObject {
    id: number;
    name: string;
}

export interface IVideoScreenElementPosition {
    height: number;
    left: number;
    top: number;
    width: number;
}

export interface IVideoScreenElementParams {
    url?: string;
    video_screen_id?: number;
    script?: string;
}

export interface IUpdateVSInput {
    script: string;
}

export enum TypeVideoScreen {
    Svg = 1,
    Composite = 2
}
