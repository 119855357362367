import {Injectable} from "@angular/core";
import {BaseHttp} from "@atl/shared/abstract";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ImitatorHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    startImitator(nodeId: number): Observable<string> {
        const url = `${this.apiRoot}/imitator/start/${nodeId}`
        return this.http.put<string>(url, {withCredentials: true})
    }

    stopImitator(nodeId: number): Observable<string> {
        const url = `${this.apiRoot}/imitator/stop/${nodeId}`
        return this.http.put<string>(url, {withCredentials: true})
    }
}
