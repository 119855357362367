// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	locales: ['en', 'ru'],
	defaultLang: 'ru',
	appSettings: {
		serversList: ['185.221.152.176'],
		version: '1.46',
		notifications: false,
		autologin: false,
		gmapsToken: 'AIzaSyDoIzWldDST4dBfRe9debLqFbdJL1mXfGI',
		websocketUrl: `ws://185.221.152.176/api/v1/ws`, // `ws://localhost:8002`
	}
};
