import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {interval, Subject} from "rxjs";
import * as moment from 'moment-timezone';
import {LtaDatePipe} from "@atl/pipes/lta-date/lta-date.pipe";

@UntilDestroy()
@Component({
    selector: 'lta-moment-clock',
    templateUrl: 'moment-clock.component.html',
    styleUrls: ['moment-clock.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MomentClockComponent implements OnInit {
    public time$ = new Subject<string>()

    constructor(private ltaDatePipe: LtaDatePipe) {
    }

    ngOnInit() {
        interval(1000)
            .pipe(
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.time$.next(this.ltaDatePipe.transform(moment().valueOf()))
            });
    }
}
