import {Injectable} from "@angular/core";
import {BaseHttp} from "@atl/shared/abstract";
import {HttpClient, HttpParams} from "@angular/common/http";
import {FormulaValidationRes} from "@atl/shared/interfaces/calc.interface";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CalcHttpService extends BaseHttp {

    constructor(private http: HttpClient) {
        super();
    }

    validateFormula(formula: string): Observable<FormulaValidationRes> {
        const formulaWithoutSpaces = formula.replace(/ /g, '')
        const url = `${this.apiRoot}/calc/validate`;
        return this.http.post<FormulaValidationRes>(url, formulaWithoutSpaces, {
            withCredentials: true,
        });
    }

    startCalcServer(nodeId: number): Observable<string> {
        const url = `${this.apiRoot}/calc/start/${nodeId}`
        return this.http.put<string>(url, {withCredentials: true})
    }

    stopCalcServer(nodeId: number): Observable<string> {
        const url = `${this.apiRoot}/calc/stop/${nodeId}`
        return this.http.put<string>(url, {withCredentials: true})
    }
}
