import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms'
import {map, tap} from 'rxjs/operators'
import {ISettings} from '../../interfaces'
import {SettingsService} from "@atl/admin/settings/services";
import {LangService} from "@atl/shared/services";
import {HmiService} from '@app/@atl/administration/hmi/services';
import {Observable} from 'rxjs';
import {ChangeDetectorAbstract} from "@atl/modules/change-detector/abstract/change-detector-abstract";
import {ModalService} from "@atl/shared/services/modal.service";
import {LtaSelectOptionModel} from "@atl/lacerta-ui-common";

export const main = {
    START_SCREEN: 'main_start_screen',
};


@UntilDestroy()
@Component({
    selector: 'lta-main-settings',
    templateUrl: 'main-settings.component.html',
    styleUrls: ['main-settings.component.scss'],
    providers: [HmiService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainSettingsComponent extends ChangeDetectorAbstract implements OnInit {
    public mainSettingsForm: FormGroup
    langSelect: LtaSelectOptionModel[] = [];

    isLoading: boolean = true
    public screensSelect$: Observable<LtaSelectOptionModel<number>[]> = this.hmiService.videoScreens$
        .pipe(
            map((screens) => {
                return screens?.filter((screen) => !screen.arguments).map((screen) => {
                    return {
                        name: screen.name,
                        value: screen.id
                    }
                })
            }));

    constructor(
        private fb: FormBuilder,
        private langService: LangService,
        private settingsService: SettingsService,
        private hmiService: HmiService,
        protected modalService: ModalService
    ) {
        super();
    }

    protected get settings(): ISettings[] {
        const values = this.mainSettingsForm.value;
        return [
            {name: main.START_SCREEN, value: String(values.startScreen)},
        ];
    }

    ngOnInit(): void {
        this.settingsService.setSaveBtnStatus(true)

        this.hmiService.getVideoScreensWithoutSvg().subscribe(() => {
            this.isLoading = false
        })

        this.langSelect = this.langService.languages.map(lng => ({
            name: lng.title,
            value: lng.locale,
            visibility: !lng.disabled
        }));

        this.mainSettingsForm = this.fb.group({
            startScreen: new FormControl(0),
            language: new FormControl(this.langService.currentLang.locale)
        })

        this.settingsService.mainSettings$
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(settings => {
                const startScreen = Number(settings.find(item => item.name === main.START_SCREEN)?.value) ?? 0
                this.mainSettingsForm.patchValue({
                    startScreen
                });

                this.trackChanges = [
                    {
                        getOriginal: () => startScreen,
                        getCopy: () => this.mainSettingsForm.get('startScreen').value
                    },
                    {
                        getOriginal: () => this.langService.currentLang.locale,
                        getCopy: () => this.mainSettingsForm.get('language').value
                    }
                ]
            });

        this.settingsService.saveBtn$.pipe(untilDestroyed(this)).subscribe(() => {
            this.onSave()
        })

        this.mainSettingsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (this.isUnchanged()) {
                this.settingsService.setSaveBtnStatus(true)
            } else {
                this.settingsService.setSaveBtnStatus(false)
            }
        });
    }

    protected onSave = () => {
        const lang = this.langService.languages.find(lng => lng.locale === this.mainSettingsForm.value.language);
        this.langService.useLocale(lang.locale);
        this.langService.saveLang(lang);
        this.settingsService.setUnsavedSettings(this.settings)
        return this.settingsService.savingStatus$.pipe(
            tap(v => {
                v && this.confirmUnSaveChanges.next(true)
            })
        )
    };

    protected onCancel = (confirm: boolean) => {
        this.confirmUnSaveChanges.next(confirm)
    };
}
